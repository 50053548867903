import styled, { css, device, themeGet } from "@styled";

export const CaseStudyWrap = styled.section`
  padding-top: 40px;
  ${device.small} {
    padding-top: 60px;
  }
  ${device.medium} {
    padding: 80px;
  }
  ${device.xlarge} {
    padding: 140px 0;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8;
      padding-bottom: 40px;
      ${device.small} {
        padding-bottom: 60px;
      }
      ${device.medium} {
        padding: 80px;
      }
      ${device.xlarge} {
        padding: 140px 0;
      }
    `}
    ${(props) =>
    props.layout === 3 &&
    css`
      ${device.large} {
        padding: 100px 0 10px;
      }
        background: #f8f8f8;
      `}
    .reviewsScriptWrapper {
      height: 450px;
      background: #eee;
      border-radius: 6px;
      margin-top: 20px;
      border: 1px solid #ddd;
      margin-bottom: 120px;
      box-shadow: 0 0 15px -5px rgb(0 0 0 / 20%);
      ${device.medium} {
        padding-bottom: 80px;
      }
      ${device.large} {
        padding-bottom: 0;
      }
      &-inner {
        height: 100%;
        ${device.medium} {
          padding-top: 24px;
        }
      }
    }
`;

export const CasestudyContentwrap = styled.div`
  margin: 15px 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 15px -5px rgb(0 0 0 / 20%);
  height: calc(100% - 30px);
  transition: ${themeGet("transition")};
  cursor: pointer;
  display:flex;
  flex-direction:column;
  &:hover {
    box-shadow: 0 0 25px -5px rgb(0 0 0 / 20%);
    transform: translateY(-3px);
  }
`;

export const ImageWrap = styled.div`
  border-radius: 6px;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 298px;
    object-fit: cover;
  }
`;

export const CaseStudyTextWrap = styled.div`
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 24px;
  flex:1;
  display:flex;
  flex-direction:column;
  h6 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  p{
    margin:0 0 15px;
    ${device.large} {
      margin:0 0 25px;
    }
  }
`;

export const ButtonWrapTop = styled.div`
  margin-top: 30px;
  ${device.large} {
    text-align: right;
    margin-top: 0;
  }
  a {
    margin: 0;
  }
`;
export const ButtonWrap = styled.div`
  margin-top: auto;
  a {
    border: none;
    color: ${themeGet("colors.primary")};
    font-size: 15px;
    font-weight: bold;
    padding: 0;
    height: initial;
    line-height: 1;
    background: none;
    &:hover {
      color: ${themeGet("colors.primary")};
    }
  }
`;
